<template>
    <metainfo></metainfo>
    <section class="overflow-x-hidden">
        <header class="h-screen w-full mr-20 bg-black">
            <div class="film absolute flex bg-black h-screen w-screen">
                <div class="my-auto md:ml-10 xl:ml-24 z-20 md:border-l-2 caption px-4 md:px-8 border-gray-900 md:rounded-r-lg shadow-xl py-6 md:w-screen-40 3xl:w-screen/3">
                    <h5 class="uppercase text-gray-400 font-medium">Out Now</h5>
                    <h1 class="uppercase text-3xl xl:text-5xl 3xl:text-7xl text-white font-medium">
                        Son of A Crime
                    </h1>
                    <p class="py-10 text-sm xl:text-lg text-gray-300">
                    Sam is a 19-year-old boy who does not know his father. His mother has been keeping the truth from him. He is on a mission to find out who his father is by any means necessary. Sometimes you get what you ask for and the truth can be a tough pill to swallow. 
                    </p>
                    <a 
                        href="https://www.amazon.com/gp/video/detail/B088PB8WW8/ref=share_ios_movie"
                        target="_blank"
                        class="bg-indigo-700 px-4 py-2 rounded-lg text-gray-200 uppercase hover:bg-indigo-600 shadow-md">
                        Stream / Buy
                    </a>
                </div>
            </div>
            <video
                muted
                id="comp-kcf8ajun_video" 
                class="shadow-2xl" 
                role="presentation" 
                crossorigin="anonymous" 
                playsinline="" 
                preload="auto" 
                loop="" 
                autoplay="" 
                tabindex="-1" 
                width="100%" 
                height="100%" 
                src="https://video.wixstatic.com/video/e0ebe9_f2d89ba7d0994543a3ca42abeb4e532f/1080p/mp4/file.mp4" 
                style="width: 100%; height: 100%; object-fit: cover; object-position: center center; opacity: 1;">
            </video>
        </header>
        <!-- Latest Work -->
        <div class="w-full min-h-screen py-10 bg-gray-950">
            <div class="px-4 md:px-4 lg:pr-32 lg:pl-12 xl:pr-64 xl:pl-48 3xl:pr-96 3xl:pl-72 mx-auto">
                <h2 class="text-2xl font-medium uppercase text-gray-400 text-center">Our Latest Work</h2>
                <div class="md:grid md:gap-6 md:grid-cols-2 mt-10 space-y-6 md:space-y-0">
                    <!-- Music Videos -->
                    <div class="bg-black rounded-xl shadow-xl hover:shadow-lg">
                        <div class="h-56 md:h-96">
                            <iframe
                                class="rounded-t-xl"
                                width="100%" 
                                height="100%" 
                                src="https://www.youtube.com/embed/fKhMB9LOXAA" 
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                            </iframe>
                        </div>
                        <div class="px-4 py-4 flex justify-between">
                            <h6 class="text-base my-auto text-gray-400 font-medium">
                                BATAY LA - Papy Mike x Baskerben
                            </h6>
                            <p class="text-base my-auto">
                                <span class="font-medium text-gray-500">Latest In:</span><br>
                                <span class="font-medium text-gray-600"> Music Videos</span>
                            </p>
                        </div>
                    </div>
                    <!-- Shows -->
                    <div class="bg-black rounded-xl shadow-xl hover:shadow-lg">
                        <div class="h-56 md:h-96">
                            <iframe
                                class="rounded-t-xl"
                                width="100%" 
                                height="100%" 
                                src="https://www.youtube.com/embed/qp7O0qnFtGg" 
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                            </iframe>
                        </div>
                        <div class="px-4 py-4 flex justify-between">
                            <h6 class="my-auto text-base text-gray-400 font-medium">
                                How Do You Do It? S1•E9: Corey Duval
                            </h6>
                            <p class="my-auto">
                                <span class="font-medium text-gray-500">Latest In:</span><br>
                                <span class="font-medium text-gray-600"> TV Shows</span>
                            </p>
                        </div>
                    </div>
                    <!-- Film -->
                    <div class="bg-black rounded-xl shadow-xl hover:shadow-lg">
                        <div class="h-56 md:h-96">
                            <iframe
                                class="rounded-t-xl"
                                width="100%" 
                                height="100%" 
                                src="https://www.youtube.com/embed/q3cMHeQG4fU" 
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                            </iframe>
                        </div>
                        <div class="px-4 py-4 flex justify-between">
                            <h6 class="my-auto font-medium text-gray-400">
                                A Battle With Fear (2021)
                            </h6>
                            <p class="my-auto">
                                <span class="font-medium text-gray-500">Latest In:</span><br>
                                <span class="font-medium text-gray-600"> Short Film</span>
                            </p>
                        </div>
                    </div>
                    <!-- Commercials -->
                    <div class="bg-black rounded-xl shadow-xl hover:shadow-lg">
                        <div class="h-56 md:h-96">
                            <iframe
                                class="rounded-t-xl"
                                width="100%" 
                                height="100%" 
                                src="https://www.youtube.com/embed/MXfYHk8oKSA" 
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                            </iframe>
                        </div>
                        <div class="px-4 py-4 flex justify-between">
                            <h6 class="my-auto font-medium text-gray-400">
                                Thrive Unburdened: Family Coaching Program
                            </h6>
                            <p class="my-auto">
                                <span class="font-medium text-gray-500">Latest In:</span><br>
                                <span class="font-medium text-gray-600"> Commercials</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="py-10 pt-20 text-center">
                    <router-link to="/work" class="bg-indigo-600 mx-auto hover:bg-indigo-700 px-4 py-2 rounded-lg uppercase text-gray-300 shadow-md">
                        See All Work
                    </router-link>
                </div>
            </div>
        </div>
        <!-- About Us -->
        <div class="w-full pt-10 pb-20 bg-gray-950 border-t-1 border-black" id="about-us">
            <div class="px-4 md:px-4 lg:pr-32 lg:pl-12 xl:pr-64 xl:pl-48 3xl:pr-96 3xl:pl-72">
                <h2 class="text-center text-2xl font-semibold uppercase text-gray-400">
                   About Us
                </h2>
                <h5 class="text-center font-medium text-gray-600">
                    Films | TV Shows | Creative Development
                </h5>
                <div class="">
                    <p class="md:w-screen/2 xl:w-screen-40 3xl:w-screen/3 mx-auto py-6 text-lg text-gray-500">
                        PlatinumTags Media is a video production company that was founded in 2015. We are headquartered in Jacksonville, FL. Our team specializes in video production, editorial, and post-production.
                        <router-link to="/services" class="px-2 py-2 text-indigo-600 hover:text-indigo-700 font-medium">
                            See Our Services
                        </router-link>
                    </p>
                   
                    
                </div>
            </div>
        </div>
        <!-- Contact Us -->
        <div class="w-full bg-gray-900 border-t-1 border-gray-950" id="contact-us">
            <div class="mx-auto pb-10">
                <div class="w-full">
                    <div class="bg-gray-900 h-96"></div>
                    <div class="px-4 md:px-4 lg:pr-32 lg:pl-12 xl:pr-64 xl:pl-48 3xl:w-screen-75 mx-auto mb-12">
                        <div class="bg-gray-950 w-full shadow-lg rounded-xl p-4 md:p-8 sm:p-12 -mt-72">
                            <h2 class="text-3xl font-bold leading-7 text-center text-gray-400">Contact Us</h2>
                            <p class="text-center py-2 text-gray-500">
                                <span class="">Leave us a note here.</span> <br>
                                <span>- OR -</span> <br>
                                Call/Text:
                                <a href="tel:929-352-4485" class="font-semibold text-gray-400"> (929) 352-4485</a>
                            </p>
                            <div v-if="emailSent" class="py-6 text-center bg-green-200 rounded-lg">
                                <h3>Your message has been sent! We'll get back to you ASAP.</h3>
                            </div>
                            <form class="" @submit.prevent="sendEmail" v-if="!emailSent">
                                <div class="md:flex items-center mt-12 text-gray-400">
                                    <div class="w-full md:w-1/2 flex flex-col">
                                        <label class="font-semibold leading-none">Name*</label>
                                        <input
                                            v-model="form.name"
                                            required
                                            placeholder="Full Name"
                                            type="text" 
                                            class="leading-none text-gray-900 p-3 focus:outline-none focus:border-indigo-700 mt-4 bg-gray-800 shadow-inner border-2 rounded-lg border-gray-900" 
                                            :class="{'border-4 border-red-500 outline-red-400' : showNameError}"/>
                                    </div>
                                    <div class="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-4">
                                        <label class="font-semibold leading-none">Email*</label>
                                        
                                        <input 
                                            v-model="form.email"
                                            type="email"
                                            placeholder="you@email.com"
                                            class="leading-none text-gray-900 p-3 focus:outline-none focus:border-indigo-700 mt-4 bg-gray-800 shadow-inner border-2 rounded-lg border-gray-900"
                                            :class="{'border-4 border-red-500 outline-red-400' : emailError}"/>
                                    </div>
                                    <div class="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-4">
                                        <label class="font-semibold leading-none">Phone</label>
                                        
                                        <input
                                            v-model="form.phone"
                                            type="tel"
                                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                            placeholder="123-456-7890"
                                            class="leading-none text-gray-900 p-3 focus:outline-none focus:border-blue-700 mt-4 bg-gray-800 shadow-inner border-2 rounded-lg border-gray-900"/>
                                    </div>
                                </div>
                                <div class="md:flex items-center mt-8 text-gray-400">
                                    <div class="w-full flex flex-col">
                                        <label class="font-semibold leading-none">Subject</label>
                                        <input
                                            v-model="form.subject"
                                            type="text" 
                                            class="leading-none text-gray-900 p-3 focus:outline-none focus:border-blue-700 mt-4 bg-gray-800 shadow-inner border-2 rounded-lg border-gray-900"/>
                                    </div>
                                    
                                </div>
                                <div>
                                    <div class="w-full flex flex-col mt-8 text-gray-400">
                                        <label class="font-semibold leading-none">Message*</label>
                                        <textarea
                                            v-model="form.message"
                                            type="text"
                                            :class="{'border-4 border-red-500 outline-red-400' : showMessageError}"
                                            class="h-40 text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-800 shadow-inner border-2 rounded-lg border-gray-900"></textarea>
                                        <p v-if="showMessageError" class="text-red-500 text-xs">Please add a message.</p>
                                    </div>
                                </div>
                                <div class="flex items-center justify-start w-full">
                                    <button
                                        :disabled="!enableSendButton"
                                        :class="{'cursor-not-allowed': !enableSendButton}"
                                        class="mt-9 font-semibold leading-none text-gray-300 py-4 px-10 bg-indigo-600 rounded-lg shadow-md hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 focus:outline-none w-full md:w-auto">
                                        Send Message
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import emailjs from 'emailjs-com'
import { validate } from '@/helpers/emailValidator'
export default {
    name: 'Home',
    metaInfo() {
        return {
            title: 'PlatinumTags Media',
        }
    },

    
    mounted(){
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    },

    data(){
        return {
            form: {
                name: null,
                email: null,
                phone: null,
                subject: null,
                message: null
            },
            emailSent: false,
            emailError: false,
        }
    },

    computed: {
        showMessageError(){
            return this.form.message === '' ? true : false
        },
        showNameError(){
            return this.form.name === '' ? true : false
        },
        enableSendButton(){
            if (
                (this.form.name && this.form.name !== '')
                && (this.form.email && this.form.email !== '')
                && (this.form.message && this.form.message !== '')
                
            ) return true

            return false
        }
    },

    methods: {
        sendEmail(){
            if (!validate(this.form.email)) return this.emailError = true
            this.emailError = false
            emailjs.send(
                    process.env.VUE_APP_EMAILJS_SERVICE_ID, 
                    process.env.VUE_APP_EMAILJS_TEMPLATE_ID, 
                    this.form, 
                    process.env.VUE_APP_EMAILJS_USER_ID
                )
                .then(() => {
                    this.form.name = ''
                    this.form.email = ''
                    this.form.message = ''
                    this.form.subject = ''
                    this.emailSent = true
                }, (error) => {
                    console.error('FAILED...', error);
                });
    
        }
    }

}
</script>

<style>
    /* header{
        background: url('../assets/img/film.jpg');
    } */
    .film{
        background: rgb(0, 0, 0, 0.4);
    }

    .caption{
        background: rgb(0, 0, 0, .4);
    }
</style>