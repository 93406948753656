<template>
    <section>
        <main>
            <router-view></router-view>
        </main>
        <footer class="mt-72">
            <Footer />
        </footer>
    </section>
</template>

<script>
import Footer from '@/components/Footer'
export default {
    name: 'Index',
    components: {
        Footer
    },

    data(){
        return {

        }
    },
    mounted(){
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    },
}
</script>

<style scoped>

</style>